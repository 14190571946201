var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"scrollable":"","persistent":"","max-width":"1000"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Order Assembly Day Rates ")]),_c('v-divider'),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('p',[_c('strong',[_c('li',[_vm._v(" The \"From - To\" date intervals cannot overlap between different day rates. ")]),_c('li',[_vm._v(" (e.g. Day Rate 1: From 01/01/2022 - To 15/01/2022 /// Day Rate 2: From 16/01/2022 - To 31/01/2022) ")]),_c('li',[_vm._v(" Periods of On-Hire days not covered by the Day Rates created below will default to use the initial Order Assembly Day Rate ")]),_c('li',[_vm._v(" If no Discount is provided for a Day Rate, the Day Rate Discount will default to the original Assembly Discount. ")]),_c('li',[_vm._v(" The From Date for any Day Rate cannot be earlier than the Hire Start Date of the Assembly. ")])])])])],1),_c('v-divider'),_c('v-row',[_c('v-row',[_c('v-col',{attrs:{"cols":"2","md":"2","sm":"2"}},[_c('v-btn',{staticStyle:{"margin-left":"10px"},attrs:{"color":"primary"},on:{"click":_vm.openAddDialog}},[_vm._v(" Add ")])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-data-table',{attrs:{"items":_vm.orderAssemblyDayRates,"headers":_vm.orderAssemblyDayRateHeaders,"items-per-page":5,"footer-props":{
                'items-per-page-options': [3, 4, 5, 10]
              },"item-key":"id"},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
              var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":"","min-width":"120"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"title":"Actions","icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-pencil")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.openEditDialog(item)}}},[_vm._v("Edit")])],1)],1),_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-delete")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.deleteDayRate(item)}}},[_vm._v("Delete")])],1)],1)],1)],1)]}}])})],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v("Done")])],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"1000px"},model:{value:(_vm.addOrEditDialog),callback:function ($$v) {_vm.addOrEditDialog=$$v},expression:"addOrEditDialog"}},[_c('v-card',[_c('v-card-title',[_c('v-flex',{attrs:{"justify-start":""}},[_vm._v(_vm._s(_vm.editDialog ? 'Edit ' : 'Add ')+" day rate")])],1),_c('v-divider'),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"4","md":"4","sm":"4","hide-details":""}},[_c('v-menu',{attrs:{"hide-details":"","close-on-content-click":true,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"hide-details":"","label":"From Date","readonly":""},model:{value:(_vm.computedFromDate),callback:function ($$v) {_vm.computedFromDate=$$v},expression:"computedFromDate"}},on))]}}]),model:{value:(_vm.fromDateMenu),callback:function ($$v) {_vm.fromDateMenu=$$v},expression:"fromDateMenu"}},[_c('v-date-picker',{attrs:{"hide-details":"","no-title":"","scrollable":""},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}})],1)],1),_c('v-col',{attrs:{"cols":"4","md":"4","sm":"4","hide-details":""}},[_c('v-menu',{attrs:{"hide-details":"","close-on-content-click":true,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"hide-details":"","label":"To Date","readonly":""},model:{value:(_vm.computedToDate),callback:function ($$v) {_vm.computedToDate=$$v},expression:"computedToDate"}},on))]}}]),model:{value:(_vm.toDateMenu),callback:function ($$v) {_vm.toDateMenu=$$v},expression:"toDateMenu"}},[_c('v-date-picker',{attrs:{"hide-details":"","no-title":"","scrollable":""},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}})],1)],1),_c('v-col',{attrs:{"cols":"4","sm":"4","md":"4","hide-details":""}},[_c('v-text-field',{attrs:{"type":"number","label":"Day Rate"},model:{value:(_vm.orderAssemblyDayRate.dayRate),callback:function ($$v) {_vm.$set(_vm.orderAssemblyDayRate, "dayRate", $$v)},expression:"orderAssemblyDayRate.dayRate"}})],1),_c('v-col',{attrs:{"cols":"4","sm":"4","md":"4","hide-details":""}},[_c('v-text-field',{attrs:{"type":"number","label":"Discount (%)"},model:{value:(_vm.orderAssemblyDayRate.discount),callback:function ($$v) {_vm.$set(_vm.orderAssemblyDayRate, "discount", $$v)},expression:"orderAssemblyDayRate.discount"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.closeAddEditDialog}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.fromDate || !_vm.toDate},on:{"click":function($event){_vm.addDialog ? _vm.addDayRate() : _vm.editDayRate()}}},[_vm._v(_vm._s(_vm.editDialog ? 'Update' : 'Create'))])],1)],1)],1),_c('snackbar-message',{attrs:{"snackbar":_vm.snackbar,"snackbar-text":_vm.successMesssage,"color":_vm.color},on:{"close":function($event){_vm.snackbar = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }