var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"scrollable":"","persistent":"","max-width":"1800"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[(_vm.order.accountCode && _vm.order.taxCode)?_c('v-card',[_c('v-card-title',{staticClass:"justify-center"},[_vm._v("Invoice - Order "+_vm._s(_vm.order.number))]),_c('v-divider'),(_vm.orderItems && _vm.orderItems.length)?_c('v-card-text',[_c('v-form',{ref:"invoiceForm"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","md":"6","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Invoice Number","readonly":"","rules":[_vm.required]},model:{value:(_vm.order.nextInvoiceNumber),callback:function ($$v) {_vm.$set(_vm.order, "nextInvoiceNumber", $$v)},expression:"order.nextInvoiceNumber"}})],1),_c('v-col',{attrs:{"cols":"6","md":"6","sm":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Invoice Date","readonly":"","rules":[_vm.required]},model:{value:(_vm.computedInvoiceDateFormat),callback:function ($$v) {_vm.computedInvoiceDateFormat=$$v},expression:"computedInvoiceDateFormat"}},on))]}}],null,false,3616708223),model:{value:(_vm.invoiceDateMenu),callback:function ($$v) {_vm.invoiceDateMenu=$$v},expression:"invoiceDateMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","max":_vm.today},on:{"input":function($event){_vm.invoiceDateMenu = false}},model:{value:(_vm.invoiceDate),callback:function ($$v) {_vm.invoiceDate=$$v},expression:"invoiceDate"}})],1)],1)],1)],1),_c('br'),_c('v-data-table',{attrs:{"loading":_vm.loading,"items":_vm.orderItems,"show-select":"","single-select":_vm.singleOrderItemSelect,"headers":_vm.mainHeaders,"search":_vm.search,"items-per-page":_vm.itemsPerPage,"single-expand":false,"show-expand":"","footer-props":{
            'items-per-page-options': [3, 4, 5, 10, 15, 30, 50, 100, -1]
          },"expanded":_vm.expand,"item-key":"orderItemId"},on:{"item-selected":_vm.selectOrderItem,"toggle-select-all":_vm.selectionEvent,"current-items":_vm.currentItems,"update:expanded":function($event){_vm.expand=$event}},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
          var item = ref.item;
          var isSelected = ref.isSelected;
          var select = ref.select;
return [_c('v-simple-checkbox',{attrs:{"value":isSelected,"readonly":item.quantity == item.totalInvoiced,"disabled":item.quantity == item.totalInvoiced,"ripple":false},on:{"input":function($event){return select($event)}}})]}},{key:"item.orderItemFirstTwoLinesDescription",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticClass:"descText",domProps:{"innerHTML":_vm._s(item.orderItemFirstTwoLinesDescription)}})]}},{key:"item.item",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.orderItems.indexOf(item) + 1))])]}},{key:"item.quantityToDeliver",fn:function(ref){
          var item = ref.item;
return [_c('v-text-field',{staticClass:"centered-input",attrs:{"min":0,"type":"number","hide-details":"","disabled":item.totalInvoiced === item.quantity ||
                  !_vm.selectedOrderItems ||
                  !_vm.selectedOrderItems.length ||
                  !_vm.selectedOrderItems.filter(
                    function (f) { return f.orderItemId === item.orderItemId; }
                  ).length,"title":item.quantityToDeliver},on:{"change":function($event){return _vm.quantityToDeliverChanged(item, 'lineItem')}},model:{value:(item.quantityToDeliver),callback:function ($$v) {_vm.$set(item, "quantityToDeliver", $$v)},expression:"item.quantityToDeliver"}},[_vm._v(_vm._s(item.quantityToDeliver))])]}},{key:"item.numberToInvoice",fn:function(ref){
                  var item = ref.item;
return [_c('v-text-field',{staticClass:"centered-input",attrs:{"min":0,"type":"number","hide-details":"","disabled":item.totalInvoiced === item.quantity ||
                  !_vm.selectedOrderItems ||
                  !_vm.selectedOrderItems.length ||
                  !_vm.selectedOrderItems.filter(
                    function (f) { return f.orderItemId === item.orderItemId; }
                  ).length,"title":item.numberToInvoice},on:{"change":function($event){return _vm.numberToInvoiceChanged(item, 'lineItem')}},model:{value:(item.numberToInvoice),callback:function ($$v) {_vm.$set(item, "numberToInvoice", $$v)},expression:"item.numberToInvoice"}},[_vm._v(_vm._s(item.numberToInvoice))])]}},{key:"expanded-item",fn:function(ref){
                  var item = ref.item;
return [_c('br'),(
                item.orderItemStockItems && item.orderItemStockItems.length
              )?_c('td',{attrs:{"colspan":_vm.mainHeaders.length + 1}},[_c('br'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.subHeaders,"items":item.orderItemStockItems,"show-select":"","single-select":_vm.singleOrderItemStockItemSelect,"item-key":"orderItemStockItemId"},on:{"item-selected":_vm.selectOrderItemStockItem,"toggle-select-all":_vm.selectionEventStockItem},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
              var item = ref.item;
              var isSelected = ref.isSelected;
              var select = ref.select;
return [_c('v-simple-checkbox',{attrs:{"value":isSelected,"readonly":item.quantity == item.invoiced,"disabled":item.quantity == item.invoiced ||
                        !item.allocated ||
                        item.allocated === 0,"ripple":false},on:{"input":function($event){return select($event)}}})]}},{key:"item.quantityToDeliver",fn:function(ref){
                        var item = ref.item;
return [_c('td',{staticClass:"text-xs-right"},[_c('v-text-field',{staticClass:"centered-input",attrs:{"min":0,"type":"number","hide-details":"","disabled":item.totalInvoiced === item.quantity ||
                          item.allocated == 0 ||
                          !_vm.selectedOrderItemStockItems ||
                          !_vm.selectedOrderItemStockItems.length ||
                          !_vm.selectedOrderItemStockItems.filter(
                            function (f) { return f.orderItemStockItemId ===
                              item.orderItemStockItemId; }
                          ).length,"title":item.quantityToDeliver},on:{"change":function($event){return _vm.quantityToDeliverChanged(item, 'stock')}},model:{value:(item.quantityToDeliver),callback:function ($$v) {_vm.$set(item, "quantityToDeliver", $$v)},expression:"item.quantityToDeliver"}},[_vm._v(_vm._s(item.quantityToDeliver))])],1)]}},{key:"item.isSerialTrackedStock",fn:function(ref){
                          var item = ref.item;
return [(item.isSerialTrackedStock)?_c('v-btn',{attrs:{"disabled":!item.allocated || item.allocated === 0,"fab":"","small":"","color":"primary"},on:{"click":function($event){return _vm.openStockSerialNumbersDialog(item)}}},[(!item.serialNumbers || !item.serialNumbers.length)?_c('v-icon',{attrs:{"small":"","title":"Add Stock Serial Numbers"}},[_vm._v("mdi-plus")]):_vm._e(),(item.serialNumbers && item.serialNumbers.length)?_c('v-icon',{attrs:{"small":"","title":"Add / View Stock Serial Numbers"}},[_vm._v("mdi-check")]):_vm._e()],1):_vm._e()]}},{key:"item.numberToInvoice",fn:function(ref){
                          var item = ref.item;
return [_c('td',{staticClass:"text-xs-right"},[_c('v-text-field',{staticClass:"centered-input",attrs:{"min":0,"type":"number","hide-details":"","disabled":item.totalInvoiced === item.quantity ||
                          !_vm.selectedOrderItemStockItems ||
                          !_vm.selectedOrderItemStockItems.length ||
                          !_vm.selectedOrderItemStockItems.filter(
                            function (f) { return f.orderItemStockItemId ===
                              item.orderItemStockItemId; }
                          ).length,"title":item.numberToInvoice},on:{"change":function($event){return _vm.numberToInvoiceChanged(item, 'stock')}},model:{value:(item.numberToInvoice),callback:function ($$v) {_vm.$set(item, "numberToInvoice", $$v)},expression:"item.numberToInvoice"}},[_vm._v(_vm._s(item.numberToInvoice))])],1)]}},{key:"item.allocated",fn:function(ref){
                          var item = ref.item;
return [_c('td',{staticClass:"text-xs-right"},[(item.allocated === 0 || !item.allocated)?_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(item.allocated))]):_c('span',{staticStyle:{"color":"green"}},[_vm._v(_vm._s(item.allocated))])])]}}],null,true),model:{value:(_vm.selectedOrderItemStockItems),callback:function ($$v) {_vm.selectedOrderItemStockItems=$$v},expression:"selectedOrderItemStockItems"}}),_c('br')],1):_c('td',{attrs:{"colspan":_vm.mainHeaders.length}},[_vm._v(" No Stock Items associated with this Line Item ")])]}}],null,false,3720167651),model:{value:(_vm.selectedOrderItems),callback:function ($$v) {_vm.selectedOrderItems=$$v},expression:"selectedOrderItems"}},[_c('template',{slot:"footer"},[_c('br'),_c('tr',[_c('td',[_c('strong',[_vm._v("Total Nett")])]),_c('td',{staticClass:"text-xs-right"},[_vm._v(_vm._s(_vm.computedNett))]),_c('td',[_c('strong',[_vm._v("Total VAT")])]),_c('td',{staticClass:"text-xs-right"},[_vm._v(_vm._s(_vm.computedVAT))]),_c('td',[_c('strong',[_vm._v("Total Gross")])]),_c('td',{staticClass:"text-xs-right"},[_vm._v(_vm._s(_vm.computedGross))])]),(
                this.order.currency == 'Euro' ||
                  this.order.currency == 'US Dollar'
              )?_c('tr',[_c('td',[_c('strong',[_vm._v("Total Nett (Pounds Sterling)")])]),_c('td',{staticClass:"text-xs-right"},[_vm._v(_vm._s(_vm.totalPoundNet))]),_c('td',[_c('strong',[_vm._v("Total VAT (Pounds Sterling)")])]),_c('td',{staticClass:"text-xs-right"},[_vm._v(_vm._s(_vm.totalPoundVat))]),_c('td',[_c('strong',[_vm._v("Total Gross (Pounds Sterling)")])]),_c('td',{staticClass:"text-xs-right"},[_vm._v(_vm._s(_vm.totalPoundGross))])]):_vm._e()])],2)],1):_c('v-card-text',[_c('span',[_c('strong',[_vm._v("There are no Order Items left to be invoiced.")])])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v("Close")]),_c('v-btn',{key:_vm.invoiceKey,attrs:{"color":"primary","disabled":!_vm.order.nextInvoiceNumber ||
              !_vm.computedInvoiceDateFormat ||
              !this.selectedOrderItems ||
              !this.selectedOrderItems.length ||
              !this.selectedOrderItems.filter(function (f) { return f.numberToInvoice > 0; })
                .length},on:{"~click":function($event){return _vm.submitInvoice()}}},[_vm._v("Submit")])],1)],1):_c('v-card',[_c('v-card-title',[_vm._v("Order Details incomplete")]),_c('v-divider'),_c('v-card-text',[_vm._v("Please ensure the Account Code and Tax Rate have been filled in Order Details screen before invoicing.")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v("Close")])],1)],1)],1),_c('v-dialog',{attrs:{"scrollable":"","persistent":"","max-width":"500"},model:{value:(_vm.serialNumbersDialog),callback:function ($$v) {_vm.serialNumbersDialog=$$v},expression:"serialNumbersDialog"}},[_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.serialNumbersDialog)?_c('v-select',{attrs:{"items":_vm.stockSerialNumbers,"menu-props":{ maxHeight: '400' },"label":"Select Serial Numbers","multiple":"","chips":"","hint":"Pick your serial numbers","persistent-hint":""},on:{"change":function($event){return _vm.stockSerialItemSelected()}},model:{value:(_vm.currentStockItem.serialNumbers),callback:function ($$v) {_vm.$set(_vm.currentStockItem, "serialNumbers", $$v)},expression:"currentStockItem.serialNumbers"}}):_vm._e()],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){;(_vm.serialNumbersDialog = false),
              (_vm.currentStockItem = null),
              (_vm.stockSerialNumbers = [])}}},[_vm._v("Ok")])],1)],1)],1),_c('snackbar-message',{attrs:{"snackbar":_vm.snackbar,"snackbar-text":_vm.successMesssage,"color":_vm.color},on:{"close":function($event){_vm.snackbar = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }