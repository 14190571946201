var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('multi-filter',{attrs:{"title":"Customer Call Sheets","filters":_vm.filters},on:{"clear-filters":_vm.clearFilters,"apply-filters":_vm.applyFilters,"remove-filter":_vm.removeFilter}},[_c('v-text-field',{attrs:{"label":"Customer"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
          'newCustomer',
          'Customer',
          _vm.newCustomer.value
        )}},model:{value:(_vm.newCustomer.value),callback:function ($$v) {_vm.$set(_vm.newCustomer, "value", $$v)},expression:"newCustomer.value"}}),_c('v-text-field',{attrs:{"label":"Branch"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
          'customerCallSheetVisitBranches',
          'Branch',
          _vm.customerCallSheetVisitBranches.value
        )}},model:{value:(_vm.customerCallSheetVisitBranches.value),callback:function ($$v) {_vm.$set(_vm.customerCallSheetVisitBranches, "value", $$v)},expression:"customerCallSheetVisitBranches.value"}})],1),_c('customer-call-sheet-table',{attrs:{"loading":_vm.loading,"customerCallSheets":_vm.filteredItems}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }