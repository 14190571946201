var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_vm._v("Stock Allocations "),_c('v-spacer')],1),_c('v-divider'),_c('v-data-table',{attrs:{"loading":_vm.loading,"items":_vm.orderStockAllocations,"headers":_vm.headers,"search":_vm.search},on:{"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"item.stockCodeDescription",fn:function(ref){
var item = ref.item;
return [_c('v-lazy',[_c('span',{attrs:{"title":_vm.getTitle(item),"color":item.color}},[_vm._v(_vm._s(item.stockCodeDescription))])])]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_c('v-lazy',[_c('span',{attrs:{"title":_vm.getTitle(item),"color":item.color}},[_vm._v(_vm._s(item.customer))])])]}},{key:"item.orderNumber",fn:function(ref){
var item = ref.item;
return [_c('v-lazy',[_c('span',{attrs:{"title":_vm.getTitle(item),"color":item.color}},[_vm._v(_vm._s(item.orderNumber))])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-lazy',[_c('v-chip',{attrs:{"title":_vm.getTitle(item),"color":item.color,"dark":""}},[_vm._v(_vm._s(item.status))])],1)]}},{key:"item.quantityToDeliver",fn:function(ref){
var item = ref.item;
return [_c('v-lazy',[_c('v-chip',{attrs:{"title":_vm.getTitle(item),"color":item.color,"dark":""}},[_vm._v(_vm._s(item.quantityToDeliver))])],1)]}},{key:"item.existingAllocation",fn:function(ref){
var item = ref.item;
return [_c('v-lazy',[_c('v-chip',{attrs:{"title":_vm.getTitle(item),"color":item.color,"dark":""}},[_vm._v(_vm._s(item.existingAllocation))])],1)]}},{key:"item.allocate",fn:function(ref){
var item = ref.item;
return [_c('v-lazy',[_c('v-chip',{attrs:{"title":_vm.getTitle(item),"color":item.color,"dark":""}},[_vm._v(_vm._s(item.allocate))])],1)]}},{key:"item.unallocated",fn:function(ref){
var item = ref.item;
return [_c('v-lazy',[_c('v-chip',{attrs:{"title":_vm.getTitle(item),"color":item.color,"dark":""}},[_vm._v(_vm._s(item.unallocated))])],1)]}},{key:"item.freeStock",fn:function(ref){
var item = ref.item;
return [_c('v-lazy',[_c('v-chip',{attrs:{"title":_vm.getTitle(item),"color":item.color,"dark":""}},[_vm._v(_vm._s(item.freeStock))])],1)]}}])})],1),_c('snackbar-message',{attrs:{"snackbar":_vm.snackbar,"snackbar-text":_vm.successMesssage,"color":_vm.color},on:{"close":function($event){_vm.snackbar = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }