var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('multi-filter',{attrs:{"title":"Equipment","filters":_vm.filters},on:{"clear-filters":_vm.clearFilters,"apply-filters":_vm.applyFilters,"remove-filter":_vm.removeFilter}},[_c('v-text-field',{attrs:{"label":"Serial Number"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
          'serialNumber',
          'Serial Number',
          _vm.serialNumber.value
        )}},model:{value:(_vm.serialNumber.value),callback:function ($$v) {_vm.$set(_vm.serialNumber, "value", $$v)},expression:"serialNumber.value"}}),_c('v-text-field',{attrs:{"label":"Model"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel('model', 'Serial Number', _vm.model.value)}},model:{value:(_vm.model.value),callback:function ($$v) {_vm.$set(_vm.model, "value", $$v)},expression:"model.value"}}),_c('v-select',{attrs:{"items":_vm.categories,"item-text":"newName","label":"Category"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
          'assetSubCategoryNewName',
          'Category',
          _vm.category.value
        )}},model:{value:(_vm.category.value),callback:function ($$v) {_vm.$set(_vm.category, "value", $$v)},expression:"category.value"}}),_c('v-text-field',{attrs:{"label":"SWL"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel('swlString', 'SWL', _vm.swl.value)}},model:{value:(_vm.swl.value),callback:function ($$v) {_vm.$set(_vm.swl, "value", $$v)},expression:"swl.value"}}),_c('v-text-field',{attrs:{"label":"Length"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel('length', 'Length', _vm.length.value)}},model:{value:(_vm.length.value),callback:function ($$v) {_vm.$set(_vm.length, "value", $$v)},expression:"length.value"}}),_c('v-autocomplete',{attrs:{"items":['Oldmeldrum', 'West Bromwich'],"label":"Base Locations","autocomplete":"on"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
          'baseLocation',
          'Base Location',
          _vm.baseLocation.value
        )}},model:{value:(_vm.baseLocation.value),callback:function ($$v) {_vm.$set(_vm.baseLocation, "value", $$v)},expression:"baseLocation.value"}}),_c('v-select',{attrs:{"items":[
        'On hire',
        'Available',
        'On hire',
        'Inspection/Repair',
        'Quarantine'
      ],"label":"Status"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel('status', 'Status', _vm.status.value)}},model:{value:(_vm.status.value),callback:function ($$v) {_vm.$set(_vm.status, "value", $$v)},expression:"status.value"}})],1),_c('hireEquipment-table',{attrs:{"loading":_vm.loading,"type":_vm.type,"equipments":_vm.filteredItems}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }