var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('multi-filter',{attrs:{"title":"Assets","filters":_vm.filters},on:{"clear-filters":_vm.clearFilters,"apply-filters":_vm.applyFilters,"remove-filter":_vm.removeFilter}},[_c('v-text-field',{attrs:{"label":"Asset Number"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel('number', 'Number', _vm.number.value)}},model:{value:(_vm.number.value),callback:function ($$v) {_vm.$set(_vm.number, "value", $$v)},expression:"number.value"}}),_c('v-text-field',{attrs:{"label":"Serial Number"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
          'serialNumber',
          'SerialNumber',
          _vm.serialNumber.value
        )}},model:{value:(_vm.serialNumber.value),callback:function ($$v) {_vm.$set(_vm.serialNumber, "value", $$v)},expression:"serialNumber.value"}}),_c('v-autocomplete',{attrs:{"label":"Category","item-text":"assetTypeName","items":[
        'CARS',
        'FIXED ASSETS - W/BROM',
        'OFFICE EQUIPMENT',
        'PLANT HIRE EQUIP - W/BROM',
        'PLANT HIRE EQUIPMENT',
        'PREMISES',
        'TRUCKS',
        'WORKSHOP EQUIPMENT'
      ]},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
          'assetTypeName',
          'Category',
          _vm.category.value
        )}},model:{value:(_vm.category.value),callback:function ($$v) {_vm.$set(_vm.category, "value", $$v)},expression:"category.value"}}),_c('v-autocomplete',{attrs:{"items":_vm.assetSubCategories,"item-text":"newName","label":"Sub-Category"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
          'subCategoryName',
          'Sub-Category',
          _vm.subCategory.value
        )}},model:{value:(_vm.subCategory.value),callback:function ($$v) {_vm.$set(_vm.subCategory, "value", $$v)},expression:"subCategory.value"}}),_c('v-select',{attrs:{"items":['Draft', 'Registered', 'Disposed', 'Cancelled'],"label":"Status"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel('status', 'Status', _vm.status.value)}},model:{value:(_vm.status.value),callback:function ($$v) {_vm.$set(_vm.status, "value", $$v)},expression:"status.value"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"value":_vm.formatDateRange(_vm.createdDate.dateRange),"clearable":"","label":"Created Date","readonly":"","prepend-icon":"mdi-calendar"},on:{"click:clear":function($event){_vm.createdDate.dateRange = []}}},on))]}}]),model:{value:(_vm.createdDateRangeMenu),callback:function ($$v) {_vm.createdDateRangeMenu=$$v},expression:"createdDateRangeMenu"}},[_c('v-date-picker',{attrs:{"range":""},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
            'created',
            'Created Date',
            _vm.createdDate.dateRange
          )},"input":_vm.closeDatePicker},model:{value:(_vm.createdDate.dateRange),callback:function ($$v) {_vm.$set(_vm.createdDate, "dateRange", $$v)},expression:"createdDate.dateRange"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"value":_vm.formatDateRange(_vm.boughtDate.dateRange),"clearable":"","label":"Bought Date","readonly":"","prepend-icon":"mdi-calendar"},on:{"click:clear":function($event){_vm.boughtDate.dateRange = []}}},on))]}}]),model:{value:(_vm.boughtDateRangeMenu),callback:function ($$v) {_vm.boughtDateRangeMenu=$$v},expression:"boughtDateRangeMenu"}},[_c('v-date-picker',{attrs:{"range":""},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
            'boughtDate',
            'Bought Date',
            _vm.boughtDate.dateRange
          )},"input":_vm.closeDatePicker},model:{value:(_vm.boughtDate.dateRange),callback:function ($$v) {_vm.$set(_vm.boughtDate, "dateRange", $$v)},expression:"boughtDate.dateRange"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"value":_vm.formatDateRange(_vm.disposedDate.dateRange),"clearable":"","label":"Disposed Date","readonly":"","prepend-icon":"mdi-calendar"},on:{"click:clear":function($event){_vm.disposedDate.dateRange = []}}},on))]}}]),model:{value:(_vm.disposedDateRangeMenu),callback:function ($$v) {_vm.disposedDateRangeMenu=$$v},expression:"disposedDateRangeMenu"}},[_c('v-date-picker',{attrs:{"range":""},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
            'disposedDate',
            'Disposed Date',
            _vm.disposedDate.dateRange
          )},"input":_vm.closeDatePicker},model:{value:(_vm.disposedDate.dateRange),callback:function ($$v) {_vm.$set(_vm.disposedDate, "dateRange", $$v)},expression:"disposedDate.dateRange"}})],1)],1),_c('asset-table',{attrs:{"loading":_vm.loading,"assets":_vm.filteredItems}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }