var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('multi-filter',{attrs:{"title":"User Timesheets","filters":_vm.filters},on:{"clear-filters":_vm.clearFilters,"apply-filters":_vm.applyFilters,"remove-filter":_vm.removeFilter}},[_c('v-text-field',{attrs:{"label":"Employee Code"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
          'userEmployeeCode',
          _vm.userEmployeeCode.displayProperty,
          _vm.userEmployeeCode.value
        )}},model:{value:(_vm.userEmployeeCode.value),callback:function ($$v) {_vm.$set(_vm.userEmployeeCode, "value", $$v)},expression:"userEmployeeCode.value"}}),_c('v-text-field',{attrs:{"label":"Employee Name"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
          'name',
          _vm.employeeName.displayProperty,
          _vm.employeeName.value
        )}},model:{value:(_vm.employeeName.value),callback:function ($$v) {_vm.$set(_vm.employeeName, "value", $$v)},expression:"employeeName.value"}}),_c('v-select',{attrs:{"items":['Awaiting Approval', 'Approved', 'Completed'],"label":"Status"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel('status', 'Status', _vm.status.value)}},model:{value:(_vm.status.value),callback:function ($$v) {_vm.$set(_vm.status, "value", $$v)},expression:"status.value"}})],1),(_vm.isAdmin || _vm.isAccounts || _vm.isManagers)?_c('user-archive-timesheet-table',{attrs:{"loading":_vm.loading,"archivedTimesheets":_vm.filteredArchivedItems}}):_vm._e(),_c('br'),(!_vm.isAdmin && !_vm.isAccounts && !_vm.isManagers)?_c('v-card',[_c('v-card-text',[_c('h3',[_vm._v(" You currently do not have the permission to access this data. Please contact the administrator. ")])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }