var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"scrollable":"","persistent":"","max-width":"1000"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"justify-center"},[_vm._v("Credits - Order "+_vm._s(_vm.order.number))]),_c('v-divider'),_c('v-card-text',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"type":"text","readonly":"","label":"Credit Note Number","rules":[_vm.required]},model:{value:(_vm.creditNoteNumber),callback:function ($$v) {_vm.creditNoteNumber=$$v},expression:"creditNoteNumber"}}),_c('v-text-field',{attrs:{"type":"text","label":"Credit Note Description","rules":[_vm.required]},model:{value:(_vm.creditNoteDescription),callback:function ($$v) {_vm.creditNoteDescription=$$v},expression:"creditNoteDescription"}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-data-table',{attrs:{"loading":_vm.loading,"items":_vm.creditNoteItems,"single-select":_vm.singleCreditNoteSelect,"show-select":"","headers":_vm.headers,"item-key":"temporaryFrontEndId"},on:{"item-selected":_vm.selectCreditNoteItem,"toggle-select-all":_vm.selectionCreditNoteEvent},scopedSlots:_vm._u([{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"descText",domProps:{"innerHTML":_vm._s(item.description)}})]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"type":"number","single-line":"","rules":[_vm.required]},on:{"input":function($event){return _vm.quantityChange(item)},"wheel":function($event){return $event.target.blur()}},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", _vm._n($$v))},expression:"item.quantity"}})]}},{key:"item.accountCode",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.accountCode))])]}},{key:"item.totalValue",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"type":"number","single-line":"","disabled":""},model:{value:(item.totalValue),callback:function ($$v) {_vm.$set(item, "totalValue", _vm._n($$v))},expression:"item.totalValue"}})]}}]),model:{value:(_vm.selectedCreditNoteItems),callback:function ($$v) {_vm.selectedCreditNoteItems=$$v},expression:"selectedCreditNoteItems"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.closeDialog(), (_vm.creditNoteItems = [])}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"disabled":!_vm.selectedCreditNoteItems || !_vm.selectedCreditNoteItems.length,"color":"primary"},on:{"click":function($event){return _vm.creditNoteConfirm()}}},[_vm._v("Confirm")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }