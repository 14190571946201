var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('multi-filter',{attrs:{"title":"Jobs","filters":_vm.filters},on:{"clear-filters":_vm.clearFilters,"apply-filters":_vm.applyFilters,"remove-filter":_vm.removeFilter}},[_c('v-text-field',{attrs:{"label":"Sale/Hire Number"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
          'orderNumber',
          'Job Number',
          _vm.orderNumber.value
        )}},model:{value:(_vm.orderNumber.value),callback:function ($$v) {_vm.$set(_vm.orderNumber, "value", $$v)},expression:"orderNumber.value"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"value":_vm.formatDateRange(_vm.invoiceDate.dateRange),"clearable":"","label":"Invoice Date","readonly":"","prepend-icon":"mdi-calendar"},on:{"click:clear":function($event){_vm.invoiceDate.dateRange = []}}},on))]}}]),model:{value:(_vm.dateRangeMenu),callback:function ($$v) {_vm.dateRangeMenu=$$v},expression:"dateRangeMenu"}},[_c('v-date-picker',{attrs:{"range":"","no-title":""},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
            'latestInvoiceDate',
            'Invoice Date',
            _vm.invoiceDate.dateRange
          )},"input":_vm.closeDatePicker},model:{value:(_vm.invoiceDate.dateRange),callback:function ($$v) {_vm.$set(_vm.invoiceDate, "dateRange", $$v)},expression:"invoiceDate.dateRange"}})],1),_c('v-text-field',{attrs:{"label":"Customer"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
          'customerName',
          'Customer',
          _vm.customerName.value
        )}},model:{value:(_vm.customerName.value),callback:function ($$v) {_vm.$set(_vm.customerName, "value", $$v)},expression:"customerName.value"}}),_c('v-text-field',{attrs:{"label":"Description"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
          'description',
          'Description',
          _vm.description.value
        )}},model:{value:(_vm.description.value),callback:function ($$v) {_vm.$set(_vm.description, "value", $$v)},expression:"description.value"}})],1),_c('job-table',{attrs:{"loading":_vm.loading,"allJobs":_vm.filteredItems}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }