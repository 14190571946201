import { render, staticRenderFns } from "./AssetCategories.vue?vue&type=template&id=57d8f760&scoped=true&"
import script from "./AssetCategories.vue?vue&type=script&lang=ts&"
export * from "./AssetCategories.vue?vue&type=script&lang=ts&"
import style0 from "./AssetCategories.vue?vue&type=style&index=0&id=57d8f760&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57d8f760",
  null
  
)

export default component.exports