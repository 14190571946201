import { render, staticRenderFns } from "./QuoteSummary.vue?vue&type=template&id=5d5fc396&scoped=true&"
import script from "./QuoteSummary.vue?vue&type=script&lang=ts&"
export * from "./QuoteSummary.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d5fc396",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VCardText,VCardTitle,VDivider,VSimpleTable})
