var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_vm._v("Quotes "),_c('v-spacer'),(!_vm.isReadOnly)?_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":_vm.exportQuotes}},[_vm._v(" Export ")]):_vm._e()],1),_c('v-data-table',{attrs:{"loading":_vm.loading,"items":_vm.quotes,"headers":_vm.headers,"search":_vm.search},on:{"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("toLocalTime")(item.created)))])]}},{key:"item.firstItemDescription",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"descText",domProps:{"innerHTML":_vm._s(item.firstItemDescription)}})]}},{key:"item.quoteGridStatus",fn:function(ref){
var item = ref.item;
return [(item.quoteGridStatus === 'Sale')?_c('v-chip',{attrs:{"color":"green","dark":""}},[_vm._v(_vm._s(item.quoteGridStatus))]):_vm._e(),(item.quoteGridStatus === 'Hire')?_c('v-chip',{attrs:{"color":"purple","dark":""}},[_vm._v(_vm._s(item.quoteGridStatus))]):_vm._e(),(item.quoteGridStatus === 'Closed')?_c('v-chip',{attrs:{"color":"Red","dark":""}},[_vm._v(_vm._s(item.quoteGridStatus))]):_vm._e(),(item.quoteGridStatus === 'Void')?_c('v-chip',{attrs:{"color":"grey","dark":""}},[_vm._v(_vm._s(item.quoteGridStatus))]):_vm._e(),(item.quoteGridStatus === 'Issued')?_c('v-chip',{attrs:{"color":"orange","dark":""}},[_vm._v(_vm._s(item.quoteGridStatus))]):_vm._e(),(item.quoteGridStatus === 'Created')?_c('span',[_vm._v(_vm._s(item.quoteGridStatus))]):_vm._e()]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.value))])]}}])})],1),_c('snackbar-message',{attrs:{"snackbar":_vm.snackbar,"snackbar-text":_vm.successMesssage,"color":_vm.color},on:{"close":function($event){_vm.snackbar = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }