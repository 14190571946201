var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"scrollable":"","persistent":"","max-width":"1200"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Service Information - "+_vm._s(_vm.orderNumber)+"/"+_vm._s(_vm.assemblyBeingServicedNumber)+" ")]),_c('v-divider'),_c('v-card-text',{staticClass:"py-3"},_vm._l((_vm.equipmentServiceHistoryModels),function(equipmentServiceHistory){return _c('v-row',{key:equipmentServiceHistory.hireEquipmentId,attrs:{"dense":""}},[_c('v-col',{staticStyle:{"text-align":"center","background-color":"lightgrey"},attrs:{"cols":"12","md":"12","sm":"12"}},[_c('strong',[_vm._v("Serial: "+_vm._s(equipmentServiceHistory.serial)+", Model: "+_vm._s(equipmentServiceHistory.model))])]),_c('v-col',{attrs:{"hide-details":"","cols":"3","md":"3","sm":"3"}},[_c('v-text-field',{attrs:{"hide-details":"","label":"Inspected By"},on:{"change":function($event){return _vm.inspectedByChanged(equipmentServiceHistory)}},model:{value:(equipmentServiceHistory.inspectedBy),callback:function ($$v) {_vm.$set(equipmentServiceHistory, "inspectedBy", $$v)},expression:"equipmentServiceHistory.inspectedBy"}})],1),_c('v-col',{attrs:{"hide-details":"","cols":"3","md":"3","sm":"3"}},[_c('v-menu',{attrs:{"dense":"","close-on-content-click":true,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"hide-details":"","label":"Inspected Date","readonly":""},model:{value:(equipmentServiceHistory.inspectedDateString),callback:function ($$v) {_vm.$set(equipmentServiceHistory, "inspectedDateString", $$v)},expression:"equipmentServiceHistory.inspectedDateString"}},on))]}}],null,true)},[_c('v-date-picker',{attrs:{"hide-details":"","no-title":"","scrollable":""},on:{"change":function($event){return _vm.dateChanged(equipmentServiceHistory)}},model:{value:(equipmentServiceHistory.inspectedDateEditString),callback:function ($$v) {_vm.$set(equipmentServiceHistory, "inspectedDateEditString", $$v)},expression:"equipmentServiceHistory.inspectedDateEditString"}})],1)],1),_c('v-col',{staticStyle:{"text-align":"center"},attrs:{"hide-details":"","cols":"6","md":"6","sm":"6"}},[_c('strong',[_vm._v("Parts Used")])]),_c('v-col',{attrs:{"hide-details":"","cols":"6","md":"6","sm":"6"}},[_c('v-textarea',{attrs:{"hide-details":"","rows":"4","label":"Notes"},model:{value:(equipmentServiceHistory.notes),callback:function ($$v) {_vm.$set(equipmentServiceHistory, "notes", $$v)},expression:"equipmentServiceHistory.notes"}})],1),(
              equipmentServiceHistory.equipmentServiceStockItems &&
                equipmentServiceHistory.equipmentServiceStockItems.length
            )?_c('v-col',{staticStyle:{"margin-top":"-30px !important"},attrs:{"cols":"6","md":"6","sm":"6"}},[_c('v-data-table',{key:"equipmentServiceStockItemId",attrs:{"dense":"","headers":_vm.stockItemHeaders,"items":equipmentServiceHistory.equipmentServiceStockItems,"items-per-page":5,"sort-by":"created","footer-props":{
                'items-per-page-options': [3, 4, 5, 10, 15, 20, 25, 30]
              }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
              var item = ref.item;
return [_c('tr',{staticStyle:{"max-height":"20px !important"}},[_c('td',{staticClass:"text-xs-right",staticStyle:{"max-height":"20px !important"}},[_vm._v(" "+_vm._s(item.stockCode)+" ")]),_c('td',{staticClass:"text-xs-right",staticStyle:{"max-height":"20px !important"}},[_vm._v(" "+_vm._s(item.quantity)+" ")])])]}}],null,true)},[_c('template',{slot:"no-data"},[_c('div')])],2)],1):_vm._e(),_c('v-col',{attrs:{"hide-details":"","cols":"6","sm":"6","md":"6"}},[_c('v-checkbox',{attrs:{"hide-details":"","disabled":equipmentServiceHistory.setHireEquipmentAvailable,"label":"Set Available"},model:{value:(equipmentServiceHistory.setHireEquipmentAvailable),callback:function ($$v) {_vm.$set(equipmentServiceHistory, "setHireEquipmentAvailable", $$v)},expression:"equipmentServiceHistory.setHireEquipmentAvailable"}})],1)],1)}),1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v("Close")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.saveChanges('save')}}},[_vm._v("Save")])],1)],1)],1),_c('snackbar-message',{attrs:{"snackbar":_vm.snackbar,"snackbar-text":_vm.successMesssage,"color":_vm.color},on:{"close":function($event){_vm.snackbar = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }