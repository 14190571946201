import { render, staticRenderFns } from "./CurrentWeek.vue?vue&type=template&id=d3097e52&scoped=true&"
import script from "./CurrentWeek.vue?vue&type=script&lang=ts&"
export * from "./CurrentWeek.vue?vue&type=script&lang=ts&"
import style0 from "./CurrentWeek.vue?vue&type=style&index=0&id=d3097e52&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d3097e52",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VCard,VChip,VDataTable})
