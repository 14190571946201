var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","title":"Edit Timesheet Entries"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-layout',{attrs:{"child-flex":""}},[_c('v-card',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}]},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"xl":"10","lg":"10","md":"12","sm":"12","xs":"12"}},[_c('v-tabs',{attrs:{"show-arrows":_vm.isMobile,"light":"","centered":""}},_vm._l((_vm.days),function(day){return _c('v-tab',{key:day,attrs:{"link":""},on:{"click":function($event){return _vm.setTab(day)}}},[_vm._v(_vm._s(day)+" ")])}),1)],1),_c('v-col',{staticStyle:{"text-align":"center"},attrs:{"cols":"12","md":"3","sm":"3"}},[_c('v-icon',{attrs:{"slot":"append","medium":""},on:{"click":function($event){_vm.fromDateMenu = true}},slot:"append"},[_vm._v("mdi-calendar-today")]),_c('h3',{staticClass:"title"},[_vm._v(_vm._s(_vm.currentDayLongString))])],1)],1),_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"3","sm":"3"}},[_c('v-text-field',{staticClass:"searchBar",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"loading":_vm.loading,"mobile-breakpoint":"0","items":_vm.userTimesheetEntries,"headers":_vm.headers,"search":_vm.search},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('span',[_c('strong',[_vm._v("No data available.")])])]},proxy:true},{key:"item.monday",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.monday)+" ")]}},{key:"item.totalHours",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.totalHours))])]}},{key:"header.monday",fn:function(ref){
var header = ref.header;
return [_c('span',{domProps:{"innerHTML":_vm._s(header.text)}})]}},{key:"header.tuesday",fn:function(ref){
var header = ref.header;
return [_c('span',{domProps:{"innerHTML":_vm._s(header.text)}})]}},{key:"header.wednesday",fn:function(ref){
var header = ref.header;
return [_c('span',{domProps:{"innerHTML":_vm._s(header.text)}})]}},{key:"header.thursday",fn:function(ref){
var header = ref.header;
return [_c('span',{domProps:{"innerHTML":_vm._s(header.text)}})]}},{key:"header.friday",fn:function(ref){
var header = ref.header;
return [_c('span',{domProps:{"innerHTML":_vm._s(header.text)}})]}},{key:"header.saturday",fn:function(ref){
var header = ref.header;
return [_c('span',{domProps:{"innerHTML":_vm._s(header.text)}})]}},{key:"header.sunday",fn:function(ref){
var header = ref.header;
return [_c('span',{domProps:{"innerHTML":_vm._s(header.text)}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.checkEdit(item))?_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1):_vm._e()]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.onEditTimesheetEntry(item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-pencil")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Edit")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.deleteTimesheetEntry(item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-delete")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Delete")])],1)],1)],1)],1),(_vm.isApproved(item))?_c('span',[_vm._v("Approved")]):_vm._e()]}}])},[_c('template',{slot:"body.append"},[(!_vm.isMobile)?_c('tr',[_c('th'),_c('th',{staticClass:"title",staticStyle:{"text-align":"center"}},[_vm._v("Daily Total:")]),_c('th',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.totalDaily)+" ")]),_c('th')]):_vm._e()])],2),(_vm.isMobile)?_c('v-divider'):_vm._e(),(_vm.isMobile)?_c('div',[_c('v-col',{staticStyle:{"text-align":"center"},attrs:{"cols":"12","md":"12","sm":"12"}},[_c('strong',[_vm._v("Daily Total: "+_vm._s(_vm.totalDaily)+" hrs")])])],1):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.closeDailyEditView()}}},[_vm._v("Done")])],1)],1)],1)],1),(_vm.editDialog)?_c('timesheet-entry-edit-dialog',{attrs:{"dialog":_vm.editDialog,"model":_vm.timesheetEntryDialogModel,"timesheetEntryExists":_vm.timesheetEntryExists,"existingTimesheetEntryId":_vm.existingTimesheetEntryId,"isWeekly":false,"day":_vm.currentDayName,"currentDayLongString":_vm.currentDayLongString},on:{"closeEditDialog":_vm.closeEditDialog}}):_vm._e(),_c('snackbar-message',{attrs:{"snackbar":_vm.snackbar,"snackbar-text":_vm.successMesssage,"color":_vm.color},on:{"close":function($event){_vm.snackbar = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }