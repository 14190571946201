var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"scrollable":"","persistent":"","max-width":"1800"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[(_vm.order.accountCode && _vm.order.taxCode)?_c('v-card',[_c('v-card-title',{staticClass:"justify-center"},[_vm._v("Invoice - Order "+_vm._s(_vm.order.number))]),_c('v-divider'),(_vm.orderAssemblies && _vm.orderAssemblies.length)?_c('v-card-text',[_c('v-form',{ref:"invoiceForm"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","md":"6","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Invoice Number","readonly":"","rules":[_vm.required]},model:{value:(_vm.order.nextInvoiceNumber),callback:function ($$v) {_vm.$set(_vm.order, "nextInvoiceNumber", $$v)},expression:"order.nextInvoiceNumber"}})],1),_c('v-col',{attrs:{"cols":"6","md":"6","sm":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Invoice Date","readonly":"","rules":[_vm.required]},model:{value:(_vm.computedInvoiceDateFormat),callback:function ($$v) {_vm.computedInvoiceDateFormat=$$v},expression:"computedInvoiceDateFormat"}},on))]}}],null,false,3616708223),model:{value:(_vm.invoiceDateMenu),callback:function ($$v) {_vm.invoiceDateMenu=$$v},expression:"invoiceDateMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","max":_vm.today},on:{"input":function($event){_vm.invoiceDateMenu = false}},model:{value:(_vm.invoiceDate),callback:function ($$v) {_vm.invoiceDate=$$v},expression:"invoiceDate"}})],1)],1)],1)],1),_c('br'),_c('v-data-table',{attrs:{"loading":_vm.loading,"items":_vm.orderAssemblies,"show-select":"","single-select":_vm.singleAssemblySelect,"headers":_vm.mainHeaders,"search":_vm.search,"items-per-page":_vm.itemsPerPage,"footer-props":{
            'items-per-page-options': [3, 4, 5, 10, 15, 30, 50, 100, -1]
          },"item-key":"orderItemId"},on:{"item-selected":_vm.selectAssembly,"current-items":_vm.currentItems,"toggle-select-all":_vm.selectionEvent},scopedSlots:_vm._u([{key:"item.orderItemFirstTwoLinesDescription",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticClass:"descText",domProps:{"innerHTML":_vm._s(item.orderItemFirstTwoLinesDescription)}})]}},{key:"item.quantity",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.isSimpleAssembly ? 'N/A' : item.quantity.toString()))])]}},{key:"item.item",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.orderAssemblies.indexOf(item) + 1))])]}}],null,false,629075815),model:{value:(_vm.selectedAssemblies),callback:function ($$v) {_vm.selectedAssemblies=$$v},expression:"selectedAssemblies"}},[_c('template',{slot:"footer"},[_c('br'),_c('td',[_c('strong',[_vm._v("Total Nett")])]),_c('td',{staticClass:"text-xs-right"},[_vm._v(_vm._s(_vm.computedNett))]),_c('td',[_c('strong',[_vm._v("Total VAT")])]),_c('td',{staticClass:"text-xs-right"},[_vm._v(_vm._s(_vm.computedVAT))]),_c('td',[_c('strong',[_vm._v("Total Gross")])]),_c('td',{staticClass:"text-xs-right"},[_vm._v(_vm._s(_vm.computedGross))])])],2)],1):_c('v-card-text',[_c('span',[_c('strong',[_vm._v("There are no Assemblies left to be invoiced.")])])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.closeDialog(true)}}},[_vm._v("Close")]),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.order.nextInvoiceNumber ||
              !_vm.computedInvoiceDateFormat ||
              !_vm.selectedAssemblies ||
              !_vm.selectedAssemblies.length},on:{"click":function($event){return _vm.submitInvoice()}}},[_vm._v("Submit")])],1)],1):_c('v-card',[_c('v-card-title',{staticClass:"justify-center"},[_vm._v("Order Details incomplete")]),_c('v-divider'),_c('v-card-text',[_vm._v("Please ensure the Account Code and Tax Rate have been filled in Order Details screen before invoicing.")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.closeDialog(true)}}},[_vm._v("Close")])],1)],1)],1),_c('snackbar-message',{attrs:{"snackbar":_vm.snackbar,"snackbar-text":_vm.successMesssage,"color":_vm.color},on:{"close":function($event){_vm.snackbar = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }