var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.type === 'active' ? 'Equipment' : 'Archived Equipment')+" "),_c('v-spacer'),(
          (_vm.isAdmin ||
            _vm.isHire ||
            _vm.isInventory ||
            _vm.isAdminDepartment ||
            _vm.isAccounts) &&
            _vm.type === 'active'
        )?_c('v-btn',{attrs:{"to":"/hire-equipment-new","color":"primary"}},[_vm._v("Add")]):_vm._e(),(!_vm.isWorkshop)?_c('v-btn',{staticStyle:{"margin-left":"10px"},attrs:{"color":"primary","outlined":""},on:{"click":_vm.exportHireEquipments}},[_vm._v(" Export ")]):_vm._e()],1),_c('v-data-table',{attrs:{"loading":_vm.loading,"items":_vm.equipments,"headers":_vm.headers,"search":_vm.search},on:{"click:row":_vm.rowClick}})],1),_c('snackbar-message',{attrs:{"snackbar":_vm.snackbar,"snackbar-text":_vm.successMesssage,"color":_vm.color},on:{"close":function($event){_vm.snackbar = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }