var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('multi-filter',{attrs:{"title":"Stock Allocations","filters":_vm.filters},on:{"clear-filters":_vm.clearFilters,"apply-filters":_vm.applyFilters,"remove-filter":_vm.removeFilter}},[_c('v-text-field',{attrs:{"label":"Stock Code / Description"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
          'stockCodeDescription',
          'Stock Code/Description',
          _vm.stockCodeDescription.value
        )}},model:{value:(_vm.stockCodeDescription.value),callback:function ($$v) {_vm.$set(_vm.stockCodeDescription, "value", $$v)},expression:"stockCodeDescription.value"}}),_c('v-text-field',{attrs:{"label":"Customer"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
          'customer',
          'Customer',
          _vm.customerName.value
        )}},model:{value:(_vm.customerName.value),callback:function ($$v) {_vm.$set(_vm.customerName, "value", $$v)},expression:"customerName.value"}}),_c('v-text-field',{attrs:{"label":"Order"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
          'orderNumber',
          'Order Number',
          _vm.orderNumber.value
        )}},model:{value:(_vm.orderNumber.value),callback:function ($$v) {_vm.$set(_vm.orderNumber, "value", $$v)},expression:"orderNumber.value"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"value":_vm.formatDateRange(_vm.createdDate.dateRange),"clearable":"","label":"Date","readonly":"","prepend-icon":"mdi-calendar"},on:{"click:clear":function($event){_vm.createdDate.dateRange = []}}},on))]}}]),model:{value:(_vm.dateRangeMenu),callback:function ($$v) {_vm.dateRangeMenu=$$v},expression:"dateRangeMenu"}},[_c('v-date-picker',{attrs:{"range":""},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
            'created',
            'Date',
            _vm.createdDate.dateRange
          )},"input":_vm.closeDatePicker},model:{value:(_vm.createdDate.dateRange),callback:function ($$v) {_vm.$set(_vm.createdDate, "dateRange", $$v)},expression:"createdDate.dateRange"}})],1)],1),_c('order-stock-allocation-table',{attrs:{"loading":_vm.loading,"orderStockAllocations":_vm.filteredItems}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }